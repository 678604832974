import React, { Component } from "react";
import {
  Typography,
  Paper,
  Tabs as Tabs1,
  Tab as Tab1,
} from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";
import AcademicAbout from "../Academics/AcademicAbout";
import CollegeGB from "../College/CollegeGB";
import CollegeNotificationShort from "../College/CollegeNotificationShort";




const smallTab = [
  {
    title: "Alumni Event",
    active: 0,
  },
  {
    title: "Alumni Registration",
    active: 1,
  },

  {
    title: "Alumni Contribution",
    active: 2,
  },
  //   {
  //     title: "Alumni Association",
  //     active: 1,
  //   },
  {
    title: "Alumni Committee",
    active: 3,
  },
  {
    title: "Alumni About",
    active: 4,
  },
];

class AlumniHomeLanding extends Component {
  state = {
    active: 0,
    dept_code: "PDUAM",

    data: {},
    isDeptLoaded: false,
  };

  __getDeptData = (dept_code) => {
    GetData(`/admin/${dept_code}/getsingledeptdata`).then((resp) => {
      //console.log(resp)
      if (resp != false) {
        this.setState({
          data: resp,
          active: 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: 0,
          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    this.__getDeptData(this.state.dept_code);
  }

  render() {
    return (
      <div>
        <Typography variant="h6">
          Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya
        </Typography>
        <Typography variant="subtitle2">
          Code: {this.state.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded && (
          <Paper square>
            <Tabs1
              value={this.state.active}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) =>
                this.setState({
                  active: newValue,
                })
              }
              aria-label=""
            >
              {smallTab.map((el, index) => (
                <Tab1 value={el.active} key={el.active} label={el.title} />
              ))}
            </Tabs1>
          </Paper>
        )}
        <br />

        {this.state.active == 0 ? (
          <CollegeNotificationShort
            type={`ALUMNI_EVENT`}
            title="Add Alumni Event"
          />
        ) : null}

        {this.state.active == 1 ? (
          <AcademicAbout
            title="Alumni Registration"
            type={`ALUMNI_REGISTRATION`}
          />
        ) : null}

        {this.state.active == 2 ? (
          <AcademicAbout
            title="Alumni Contribution"
            type={`ALUMNI_CONTRIBUTION`}
          />
        ) : null}

        {this.state.active == 3 ? (
          <CollegeGB
            title="Alumnii Committee"
            {...this.state.data}
            dept_code={`ALUMNI_COMMITTEE`}
          />
        ) : null}

        {this.state.active == 4 ? (
          <AcademicAbout
            title="About Alumni"
            type={`ALUMNI_ABOUT`}
          />
        ) : null}

      </div>
    );
  }
}

export default AlumniHomeLanding;
